














































































































































import { Locale } from '@/edshed-common/i18n'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Api, HttpResponseError, PhonemesInfo } from '@/edshed-common/api'
import { Component, Mixins } from 'vue-property-decorator'
import { Howl } from 'howler'
import axios from 'axios'
import AddPhoneme from '@/components/views/components/AddPhoneme.vue'
import EditPhoneme from '@/components/views/components/EditPhoneme.vue'
import { getFilesBucketName } from '@/utils/helpers'

@Component({
  name: 'Phonemes',
  components: {
    AddPhoneme,
    EditPhoneme
  }
})

export default class Phonemes extends Mixins(ComponentHelper) {
  loading: boolean = false

  phonemesData: PhonemesInfo[] = []

  phoneme: PhonemesInfo | null = null

  phonemesSearch: string = ''

  showAddPhoneme:boolean = false

  showEditPhoneme: boolean = false

  searchLocale: Locale = 'en_GB'

  mounted () {
    if (!this.$store.state.user.superuser) {
      this.$router.push('/noaccess')
    } else {
      this.getPhonemesData()
    }
  }

  // methods
  async getPhonemesData () {
    try {
      this.loading = true
      const res = await Api.getPhonemes(this.searchLocale)
      if (res) {
        this.phonemesData = res
      }
    } catch (err) {
      this.$buefy.toast.open({ message: 'Could not retrieve the phonemes', position: 'is-bottom', type: 'is-danger', duration: 3000 })
    } finally {
      this.loading = false
    }
  }

  openEditPhoneme (phoneme) {
    this.phoneme = phoneme
    this.showEditPhoneme = true
  }

  didAddPhoneme (phoneme: PhonemesInfo) {
    this.showAddPhoneme = false
    if (this.searchLocale === phoneme.locale) {
      this.phonemesData.push(phoneme)
    }
    this.$buefy.toast.open({ message: 'Phoneme added successfully', position: 'is-bottom', type: 'is-success', duration: 3000 })
  }

  didEditPhoneme (phoneme: PhonemesInfo) {
    this.showEditPhoneme = false
    const index = this.phonemesData.findIndex(p => p.id === phoneme.id)
    if (this.searchLocale === phoneme.locale) {
      this.phonemesData.splice(index, 1, phoneme)
    } else {
      this.phonemesData.splice(index, 1)
    }

    this.$buefy.toast.open({ message: 'Phoneme edited successfully', position: 'is-bottom', type: 'is-success', duration: 3000 })
  }

  handleError (err: unknown, action: string) {
    this.showAddPhoneme = false
    this.showEditPhoneme = false
    if (err instanceof HttpResponseError && err.status === 409) {
      this.$buefy.toast.open({ message: err.message, position: 'is-bottom', type: 'is-danger', duration: 3000 })
    } else {
      this.$buefy.toast.open({ message: `Could not ${action} the phoneme`, position: 'is-bottom', type: 'is-danger', duration: 3000 })
    }
  }

  deletePhoneme (id: number) {
    if (!this.phonemesData) {
      return
    }
    this.$buefy.dialog.confirm({
      title: 'Deleting Phoneme',
      message: 'Are you sure you want to delete this phoneme? This action cannot be undone.',
      confirmText: 'Delete',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: async () => {
        this.loading = true
        try {
          await Api.deletePhoneme(id)
          const index = this.phonemesData.findIndex(p => p.id === id)
          this.phonemesData.splice(index, 1)
          this.$buefy.toast.open({ message: 'Phoneme deleted successfully', position: 'is-bottom', type: 'is-success', duration: 3000 })
        } catch (err) {
          this.$buefy.toast.open({ message: 'Could not delete phoneme', position: 'is-bottom', type: 'is-danger', duration: 3000 })
        } finally {
          this.loading = false
        }
      }
    })
  }

  async playSoundForPhoneme (code: string, locale: Locale) {
    if (!code) {
      return
    }

    const filename = 'https://' + getFilesBucketName() + '/audio/dictionary/' + locale + '/PHONICS/mp3/' + code.toLowerCase() + '.mp3'
    const oggFile = 'https://' + getFilesBucketName() + '/audio/dictionary/' + locale + '/PHONICS/ogg/' + code.toLowerCase() + '.ogg'

    await axios.get(filename, { headers: [], withCredentials: false })
    await axios.get(oggFile, { headers: [], withCredentials: false })

    const sound = new Howl({
      src: [oggFile, filename]
    })

    sound.play()
  }

  playSoundEffect (action: string) {
    const oggSound = `/audio/${action}-recording-sound.ogg`
    const mp3Sound = `/audio/${action}-recording-sound.mp3`

    const sound = new Howl({
      src: [oggSound, mp3Sound]
    })

    sound.play()
  }

  // computed
  get filteredPhonemesData () {
    if (this.phonemesData) {
      if (this.phonemesSearch) {
        const search = this.phonemesSearch.replace('/', '')
        return this.phonemesData.filter((phoneme) => {
          return phoneme.code.includes(search) || phoneme.name.includes(search) || phoneme.ipa?.includes(search)
        })
      } else {
        return this.phonemesData
      }
    }

    return []
  }
}
