








































































import ComponentHelper from '@/mixins/ComponentHelper'
import { Api, FileUpload, AddPhonemesRequest } from '@/edshed-common/api'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { Locale } from '@/edshed-common/i18n'
import FileInput from '@/edshed-common/components/FileInput.vue'
import RecordAudio from '@/edshed-common/components/RecordAudio.vue'

@Component({
  name: 'AddPhoneme',
  components: {
    FileInput,
    RecordAudio
  }
})

export default class AddPhoneme extends Mixins(ComponentHelper) {
  @Prop({ required: true }) searchLocale!: Locale

  loading: boolean = false
  code: string | null = null
  name: string | null = null
  ipa: string | null = null
  record: { new_file?: FileUpload } = {}
  formErrors: string[] = []
  showRecordAudio = false
  showErrorDiv = false

  handleStartRecording () {
    this.$emit('startRecording')
  }

  handleStopRecording () {
    this.$emit('stopRecording')
  }

  toggleRecordAudio () {
    this.showRecordAudio = !this.showRecordAudio
    this.record = {}
  }

  handleFileUpload (fileData: FileUpload) {
    this.record = { new_file: fileData }
  }

  handleAudioRecorded (fileData: FileUpload) {
    this.record = { new_file: fileData }
  }

  async handleSubmit () {
    this.loading = true
    try {
      this.showErrorDiv = false
      this.formErrors = []
      if (this.record.new_file && this.record.new_file.extname && this.record.new_file.size) {
        if (this.record.new_file.size > 500000) {
          this.formErrors.push('The file size exceeds the limit allowed')
        }
        if (!/(.mp3|.ogg|.wav)/.test(this.record.new_file.extname)) {
          this.formErrors.push('Wrong file type')
        }
      }
      if (!this.code) {
        this.formErrors.push('The code field is required')
      }
      if (!this.name) {
        this.formErrors.push('The name field is required')
      }
      if ((this.code !== '_' || this.name !== 'no sound') && !this.ipa) {
        this.formErrors.push('The ipa field is required')
      }
      if (this.formErrors.length > 0) {
        this.showErrorDiv = true
        return
      }

      let new_file: FileUpload | null = null
      if (this.record.new_file && this.record.new_file.data && this.record.new_file.extname && this.record.new_file.size) {
        new_file = this.record.new_file
      }

      const phoneme: AddPhonemesRequest = {
        locale: this.searchLocale,
        code: this.code!,
        name: this.name!,
        ipa: this.ipa,
        file: new_file
      }

      const res = await Api.addPhoneme(phoneme)
      this.$emit('added-phoneme', res)
    } catch (err: unknown) {
      this.$emit('error', err, 'add')
    } finally {
      this.loading = false
    }
  }
}
