import { render, staticRenderFns } from "./EditPhoneme.vue?vue&type=template&id=49209c86&scoped=true&"
import script from "./EditPhoneme.vue?vue&type=script&lang=ts&"
export * from "./EditPhoneme.vue?vue&type=script&lang=ts&"
import style0 from "./EditPhoneme.vue?vue&type=style&index=0&id=49209c86&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49209c86",
  null
  
)

export default component.exports