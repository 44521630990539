



















import { Vue, Component } from 'vue-property-decorator'
import { FileUpload } from '../api'

/** Example usage: <record-audio /> */
@Component({
  name: 'RecordAudio'
})
export default class RecordAudio extends Vue {
  filesrc: string | null = null
  // @ts-ignore
  mediaRecorder: MediaRecorder | null = null
  chunks: any[] = []
  showMicrophone: boolean = false
  showPlayButton: boolean = false
  recording: boolean = false
  record: FileUpload = { data: '', extname: '' }

  constraints = { audio: true }

  mounted () {
    this.getMedia()
  }

  async getMedia () {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(this.constraints)

      stream.getTracks().forEach(function (track) {
        track.stop()
      })
    } catch (err) {
    }
  }

  async restartStream () {
    const newStream = await navigator.mediaDevices.getUserMedia(this.constraints)
    // @ts-ignore
    this.mediaRecorder = new MediaRecorder(newStream)
  }

  async startRecord () {
    try {
      this.$emit('startRecording')
      await this.restartStream()

      if (this.mediaRecorder) {
        this.showPlayButton = false
        this.filesrc = null
        this.chunks = []
        this.recording = true

        this.mediaRecorder.start()
        // @ts-ignore
        this.mediaRecorder.ondataavailable = (e) => {
          this.chunks.push(e.data)
        }
      }
    } catch (err) {
      this.$buefy.toast.open({ message: 'Could not record sound', type: 'is-danger' })
      this.recording = false
      // @ts-ignore
      this.mediaRecorder!.stream.getTracks().forEach(function (track) {
        track.stop()
      })
    }
  }

  stopRecord () {
    this.$emit('stopRecording')
    try {
      if (this.mediaRecorder) {
        this.record = { data: '', extname: '' }
        this.recording = false
        // @ts-ignore
        this.mediaRecorder!.stream.getTracks().forEach(function (track) {
          track.stop()
        })

        this.mediaRecorder.addEventListener('stop', () => {
          const blob = new Blob(this.chunks, { type: 'audio/mp3' })

          const reader = new FileReader()

          reader.addEventListener('loadend', () => {
            console.log('reader finished')
            this.record.data = reader.result as string
            this.record.extname = '.mp3'
            this.record.size = blob.size
            // show audio
            this.showPlayButton = true
            this.filesrc = URL.createObjectURL(blob)

            this.$emit('audioRecorded', this.record)
          })

          reader.readAsDataURL(blob)
        })

        this.mediaRecorder.stop()
      }
    } catch (err) {
      this.$buefy.toast.open({ message: 'Could not record sound', type: 'is-danger', position: 'is-bottom' })
    }
  }
}
